$solidWhite:    #FFFFFF;
$doveGrey:      #707070;
$shadow:        #00000029;
$scorpion:      #5F5F5F;
$alabaster:     #F8F8F8;
$alto:          #DEDEDE;
$mercury:       #E8E8E8;
$frenchGray:    #BABABC;
$manatee:       #8E8E90;
$froly:         #F48989;
$fern:          #5CBC79;
$cherokee:      #F9C988;
$malibu:        #88ADFF;
$mojo:          #CC553B;
$casablanca:    #F9B657;
$navajo-white:  #FFE3A8;

$brightGreen:   #65FF00;
$aquamarine:    #65FFFF;
$cornflowerBlue:#6C9EEB;
$goldenGlow:    #FCE698;
$sprout:        #B6D7A8;
$tonysPink:     #E99998;
$fireBush:      #E69038;
$gothic:        #77909C;

$whatsapp:      #59CE72;
$whatsapp-hover:#CC553B;
$whatsappBullet:#F48989;
