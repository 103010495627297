@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import "./assets/variables.scss";
@import "./assets/_forms";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@angular/cdk/overlay-prebuilt.css";
@import "./assets/ng2-date-picker";
@import "./assets/icomoon/style.css";

:root {
  font-size: 62.5%;
}

body{
    font-family: 'Roboto', sans-serif;
    font-weight: 400;

    margin: 0;

    background-color: $mercury;
}

.row{
    margin:0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: $shadow;
}

::-webkit-scrollbar-thumb:hover {
  background: $scorpion;
}

.white-space-nowrap {
    white-space: nowrap;
}

.toast-container .ngx-toastr {
  box-shadow: 0px 3px 6px $shadow;
  font-size: 1.6rem;
  width: 100%;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0px 3px 6px $shadow;
}

.toast-close-button {
  margin: 5px
}

.toast-title {
  font-size: 1.8rem;
}

.toast-error {
  background-color: #00000099;
}

.toast-success {
  background-color: $fern;
}

.toast-info {
  background-color: $mojo;
}

.toast-warning {
  background-color: $casablanca;
}

.toast-progress {
  background-color: $manatee;
}

input {
  &:disabled {
    background-color: $alto;
  }
}

.tox-tinymce-aux {
  display: none;
}

/* CDK Drag n Drop */
.cdk-drag-preview {
  .kanban-card {
    overflow: auto;
  }
}

.kanban-card {
  background-color: white;
  border-radius: 6px;
  padding: 2px 4px;

  .text {
    font-size: 1.4rem;
    display: flex;
    overflow: hidden;
    align-items: center;
    gap: 4px;
  }

  a.text {
    pointer-events: none;
  }

  .icon-Icon-awesome-whatsapp {
    color: $whatsapp;
    cursor: pointer;
  }
}
