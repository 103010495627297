.custom-theme {
  &--no-input {
    .dp-input-container {
      opacity: 0;
      display: none;
    }
  }

  .dp-calendar-nav .dp-current-location-btn {
    padding: 0 !important;
  }

  .dp-calendar-nav-container {
    border: none !important;
    padding: 0 !important;
    margin-bottom: 10px;
    font-size: medium;
    margin: 1px;
  }

  .dp-calendar-wrapper {
    padding: 0 !important;
    display: block !important;
    width: 100% !important;
    border: none;
    font-size: medium;
    margin: 1px;
  }

  .dp-calendar-weekday {
    color: $mojo;
    border: none;
    font-size: medium;
    margin: 1px;
  }

  .dp-calendar-nav-left,
  .dp-calendar-nav-right {
    background-color: transparent;
    border: none;
    font-size: medium;
    margin: 1px;
  }

  .dp-calendar-day {
    padding: 0 !important;
    background-color: transparent;
    border: none;
    font-size: medium;
    margin: 1px;
  }

  .dp-calendar-day:hover {
    background-color: $shadow;
    border-radius: 50%;
  }

  .dp-selected {
    border-radius: 50%;
    background-color: $mojo !important;
  }

  .dp-current-day {
    border: 1px solid $mojo;
    border-radius: 50%;
  }
}
